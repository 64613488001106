@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    // TODO:: Ronald, please tweak the styles

    position: fixed;
    font-size: 0.875rem;
    letter-spacing: 0.03em;
    z-index: $z-index-snackbar;
    display: flex;
    left: 1rem;
    bottom: 1rem;
    right: auto;
    justify-content: flex-start;
    align-items: center;
    min-width: 250px;
    border-radius: 0.5rem;
    padding: 1rem;
    transition: transform 0.2s ease-out;
    box-shadow: 5px 10px 30px rgba(#000, 0.3);

    &.state-entering,
    &.state-entered {
        transform: translateX(0);
    }

    &.state-exiting,
    &.state-exited,
    &.state-unmounted {
        transform: translateX(-500px);
    }

    .action,
    .close-button {
        margin-left: 0.5rem;
    }

    .title {
        font-weight: bold;
        margin-bottom: 0.25rem;
    }

    &.info {
        background-color: $white;
    }

    &.success {
        background-color: $success-color;
        color: $white;

        .close-button {
            color: $white;
        }
    }

    &.warning {
        background-color: $warning-color;
        color: $white;
    }

    &.error {
        background-color: $error-color;
        color: $white;
    }

    .icon {
        margin-right: 0.5rem;
    }
}
