@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1500;
    background-color: $grey-900; // assuming you have a variable for colors.blueGrey[900]
    opacity: 0.7;
    justify-content: center;
    justify-items: center;
    padding: rc(32);

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        text-align: center;
        color: $white; // assuming you have a variable for theme.palette.white
    }

    .icon {
        font-size: rc(32);
        width: rc(32);
        height: rc(32);
        margin: 0 auto;
    }
}
