@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.navigation {
    background-color: $white;

    // ELEMENTS

    .list {
        margin: 0;
        padding: 0;
        min-width: 10rem;
        list-style: none;
    }
}

.navigation-item {
    position: relative;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid $grey-200;
    overflow: hidden;
    @include transition(all $transition-duration);

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -0.5rem;
        top: 0;
        bottom: 0;
        background-color: $primary-color;
        width: 0.5rem;
        @include transition(all $transition-duration);
    }

    // ELEMENTS

    .top {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .icon {
        display: inline-block;
        margin-right: 1rem;
        width: 1rem;
        text-align: center;
        line-height: 1.5rem;
    }

    .title {
        font-size: 1rem;
        line-height: 1.5rem;
        flex: 1;
        @include transition(0.3s all);
    }

    .children {
        display: none;
        margin-top: 1rem;
        padding-left: 2rem;
    }

    // STATES

    &:not(:hover) {
        .action-sheet {
            opacity: 0;
        }
    }

    &:not(.is-active, .is-disabled) {
        cursor: pointer;

        &:hover {
            background-color: $hover-background-color;
        }
    }

    &.is-active {
        &:before {
            left: 0;
        }

        .title {
            font-weight: 600;
        }

        .children {
            display: block;
        }
    }

    &.is-disabled {
        opacity: 0.5;
        user-select: none;
    }
}

.child-navigation-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;

    // ELEMENTS

    .title {
        display: inline-block;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding: 0.125rem 0;
        flex: 1;
        @include transition(0.3s all);
    }

    // STATES

    &:not(:hover) {
        .action-sheet {
            opacity: 0;
        }
    }

    &.is-active {
        .title {
            color: $primary-color;
        }
    }
}
