@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.page {
    flex: 1;
    width: 0;

    .back-button {
        margin: 0.75rem 1rem;
    }

    .heading {
        margin-left: $section-padding-x;
    }

    .content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
}
