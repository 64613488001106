@mixin border-radius($radius) {
    background-clip: padding-box; // stops bg color from leaking outside the border
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
    background-clip: padding-box; // stops bg color from leaking outside the border
    border-top-left-radius: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
}

@mixin box-shadow($shadow1, $shadow2:false, $shadow3:false, $shadow4:false, $shadow5:false) {
    $params: $shadow1;
    @if $shadow2 {
        $params: $shadow1, $shadow2;
    }
    @if $shadow3 != false {
        $params: $shadow1, $shadow2, $shadow3;
    }
    @if $shadow4 != false {
        $params: $shadow1, $shadow2, $shadow3, $shadow4;
    }
    @if $shadow5 != false {
        $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5;
    }

    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin;
    transform-origin: $origin;
}

@mixin transition($transition...) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin placeholder {

    &::-webkit-input-placeholder {
        @content
    }

    &:-moz-placeholder {
        @content
    }

    &::-moz-placeholder {
        @content
    }

    &:-ms-input-placeholder {
        @content
    }
}

@mixin filter($value) {
    -webkit-filter: $value;
    filter: $value;
}

@mixin no-select() {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

@mixin fixed-line-number($number-of-lines, $line-height) {
    height: $line-height * $number-of-lines;
    overflow: hidden;
    word-wrap: break-word;
    box-sizing: content-box;

    @if $number-of-lines == 1 {
        text-overflow: ellipsis;
        white-space: nowrap;
    } @else {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $number-of-lines;
        white-space: normal;
    }
}

@mixin flex-loop($number) {

    @for $i from 1 through $number {

        &.flex-#{$i} {
            flex: $i;
        }
    }
}
