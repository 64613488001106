@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.filters {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1.5rem;

    .list {
        display: flex;
        flex: 1;
        flex-flow: row wrap;
        justify-content: flex-start;
        gap: 0.75rem;
    }

    .item {

    }

    .item,
    .add-filter {
        box-sizing: border-box;
        display: inline-flex;
    }

    .add-filter {

    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;
    }
}

.filter {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    .label,
    .remove-button {
        height: 2.25rem;
        background-color: $grey-200;
        margin: 0;
    }

    .label {
        line-height: 1.25rem;
        padding: 0.5rem 0.5rem 0.5rem 0.75rem;
        box-sizing: border-box;
        display: inline-block;
        border-right: 0.0625rem solid $grey-300;
        @include border-radii(1.125rem, 0, 0, 1.125rem);
    }

    .remove-button {
        line-height: 1.25rem;
        padding: 0.5rem 0.75rem 0.5rem 0.5rem;
        width: auto;
        box-sizing: border-box;
        border-left: 0.0625rem solid $grey-300;
        @include border-radii(0, 1.125rem, 1.125rem, 0);

        &:hover {
            color: $grey-800;
            background-color: $grey-300;
        }
    }
}
