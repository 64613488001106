@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.uppercase {
    text-transform: uppercase;
    font-weight: bold;
}

.root {
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $input-font-size;
    font-family: inherit;
    background-color: $input-background-color;
    border-radius: $input-border-radius;
    border: $input-border-width solid $input-border-color;
    text-align: left;
    cursor: pointer;
    @include transition(all 0.3s);

    .button-label {
        flex: 1;
    }

    .arrow-button {
        text-align: center;
        color: $grey-500;
        width: 3rem;
        border-left: 0.0625rem solid $grey-300;
        margin-left: 8px;
        @include border-radius(0);
    }

    &.variant-default {
        min-width: 12rem;
        padding: $input-padding-y 0.5rem $input-padding-y $input-padding-x;
    }

    &.variant-filter {
        min-width: 12rem;
        padding: $input-padding-y 0.5rem $input-padding-y $input-padding-x;
        border-color: transparent;
        height: 55px;
    }

    &.variant-inline {
        min-width: 5rem;
        padding: $input-padding-y * 0.25 $input-padding-x * 0.25
            $input-padding-y * 0.25 $input-padding-x;
        font-size: $input-font-size * 0.75;

        .arrow-button {
            width: 2rem;
        }
    }

    &.variant-text {
        min-width: 5rem;
        padding: 0;
        font-size: $input-font-size * 0.875;
        border: 0;
        font-weight: bold;

        .button-label {
            text-decoration: underline;
        }

        .arrow-button {
            width: 2rem;
            border: 0;
            color: inherit;
            text-decoration: none;
        }
    }

    &.variant-button {
        min-width: 12rem;
        border: 0;
        padding: $button-padding-y 0.5rem $button-padding-y $button-padding-y;
        border-radius: 0.5rem;

        .arrow-button {
            border: 0;
        }
    }

    // COLORS
    &.color-default {
        // STATES
        &:focus {
            &:not([aria-disabled='true']) {
                background-color: $hover-background-color;
            }
        }

        &.variant-button {
            background-color: $hover-background-color;
        }

        &:global {
            &.Mui-focused,
            &.Mui-focusVisible {
                background-color: $hover-background-color;
            }
        }
    }

    &.color-error {
        background-color: $error-color;
        border-color: $error-color;
        color: $white;

        .arrow-button {
            color: $white;
            border-color: $white;
        }
    }

    &.color-success {
        background-color: $success-color;
        border-color: $success-color;
        color: $white;

        .arrow-button {
            color: $white;
            border-color: $white;
        }
    }

    &.color-warning {
        background-color: $warning-color;
        border-color: $warning-color;
        color: $white;

        .arrow-button {
            color: $white;
            border-color: $white;
        }
    }
}

.popup {
    z-index: $z-index-popup-content;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    min-width: 12rem;

    .list {
        background-color: $white;
        border-radius: 0.5rem;
        margin: 0;
        padding: 0.5rem 0;
        max-height: 50vh;
        overflow-y: auto;
        @include box-shadow($dropdown-shadow);
    }

    .option {
        margin: 0;
        padding: 0.5rem 1rem;
        cursor: pointer;

        // STATES

        &:hover {
            &:not([aria-disabled='true']) {
                background-color: $hover-background-color;
            }
        }

        &:focus {
            outline: none;
            &:not([aria-disabled='true']) {
                background-color: $hover-background-color;
            }
        }
    }
}
