@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    position: relative;
    padding: ($input-padding-y + $input-border-width)
        ($input-padding-x + $input-border-width);
    background-color: $input-background-color;
    border-radius: $input-border-radius;
    border: none;
    outline: none;
    font-size: $input-font-size;
    font-family: inherit;
    width: 100%;
    resize: none;
    @include transition(all 0.3s);

    &:focus {
        border-color: $input-border-color-focus;
    }

    &.is-invalid {
        background-color: $input-background-color-invalid;
    }

    // MODIFIERS

    &.border {
        padding: $input-padding-y $input-padding-x;
        border: $input-border-width solid $input-border-color;
    }

    &.is-invalid {
        &.border {
            border-color: $input-border-color-invalid;
        }

        &:not(.border) {
            background-color: $input-background-color-invalid;
        }
    }
}
