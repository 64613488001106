@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    // ELEMENTS

    .wrapper {
        border: none;
        outline: none;
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        @include transition(all 0.3s);

        .input-wrapper {
            border: none;
            flex: 1;

            .input {
                width: 100%;
                box-sizing: content-box;
                border: none;
                background-color: transparent;
                font-size: $input-font-size;
                font-family: inherit;
                padding: ($input-padding-y + $input-border-width) 0
                    ($input-padding-y + $input-border-width)
                    ($input-padding-x + $input-border-width);
                outline: 0 !important;
            }
        }

        .arrow-button {
            color: $grey-500;
            width: 3rem;
            border-left: 0.0625rem solid $grey-300;
            @include border-radius(0);
        }
    }

    // MODIFIERS

    &.default {
        .wrapper {
            background-color: $input-background-color;
            border-radius: $input-border-radius;
        }
    }

    &.border {
        .wrapper {
            border: $input-border-width solid $input-border-color;
        }
    }

    &.filter {
        .wrapper {
            background-color: $grey-200;

            .input-wrapper {
                .input {
                    color: $grey-700;
                    font-size: 0.75rem;
                    font-weight: 600;
                    line-height: 1.25rem;
                    padding: 0.5rem 0 0.5rem 0.5rem;
                    min-width: 3rem;

                    @include placeholder {
                        color: $grey-700;
                    }
                }
            }

            .arrow-button {
                width: 2rem;
                line-height: 1.25rem;
                padding: 0.5rem 0;
                text-align: center;
                border-left: none;
            }
        }
    }

    // STATES

    &.focused {
    }
}

.dropdown {
    background-color: $white;
    border-radius: 0.5rem;
    z-index: $z-index-popup-content;
    @include box-shadow($dropdown-shadow);

    // ELEMENTS

    .dropdown-wrapper {
        // @Ronald - 'overflow-y' on this element created bugs
        // vertical scroll doesn't work for arrow up/down on the keyboard
        // I've moved that styles to the .list
    }

    .clear-button {
        text-align: left;
        padding: 0.5rem 1rem;
        border-radius: 0;
        font-size: 12px;
        text-transform: none;
        color: $primary-color;
        text-decoration: underline;
    }

    .loader {
        display: inline-block;
        margin-left: 0.5rem;
    }

    .list {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0.5rem 0;
        max-height: 50vh;
        overflow-y: auto;
        font-size: 0.875rem;
    }

    .divider {
        border: none;
        border-bottom: 0.0625rem solid $grey-400;
        margin: 0.5rem 0;
    }

    .item {
        margin: 0;
        padding: 0 0 0 0.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: row;

        // ELEMENTS

        .item-label {
            flex: 1;
            display: inline-block;
            padding: 0.5rem 1rem 0.5rem 0;
        }

        .uncheck-button {
            padding: 0;
            text-align: center;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            font-size: 1rem;
        }

        .check-icon {
            padding: 0;
            text-align: center;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            font-size: 1rem;
        }

        // STATES

        &:global {
            &.Mui-focused,
            &.Mui-focusVisible {
                background-color: $hover-background-color;
            }
        }
    }

    .no-options {
        margin: 0;
        display: block;
        padding: 0.5rem 1rem;
        color: $grey-400;
    }
}
