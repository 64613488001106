@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    width: 100%;
    border-collapse: collapse;
}

.toolbar {
    padding: 0.5rem 0;
    width: 100%;
    color: $grey-900;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.select-label,
.displayed-rows {
    font-size: 0.75rem;
    margin-right: 0.5rem;
}

.spacer {
}

.select {
    margin-right: auto;
}

.option {
}

.actions {
    button {
        border: none;
        background-color: transparent;
        color: $grey-500;
        cursor: pointer;
        font-size: 1rem;
        line-height: 1.5rem;
        height: 1.5rem;
        width: 1.5rem;
        padding: 0;
        @include border-radius(1rem);
        @include transition(all $transition-duration);

        &:hover {
            color: $grey-800;
            background-color: $hover-background-color;
        }
    }
}
