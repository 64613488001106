@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .item {
        display: inline-block;
    }

    .link {
        text-decoration: underline;
        cursor: pointer;
    }

    .divider,
    .link,
    .title {
        font-weight: 500;
        font-size: 0.75rem;
        text-transform: uppercase;
    }

    .title-wrapper {
        display: inline-block;
        white-space: nowrap;
        max-width: 130px;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .divider {
        margin: 0 0.75rem;
    }
}
