$scrollbar-regular-bg: transparent;
$scrollbar-regular-thumb-bg: transparent;
$scrollbar-active-bg: rgba(#efefef, 0.6);
$scrollbar-active-thumb-bg: rgba(#aaa, 0.6);
$transition-duration: 0.2s ease;
$transition: all #{$transition-duration};

.custom-scrollbar {
    scrollbar-width: 6px;
    scrollbar-color: $scrollbar-regular-thumb-bg $scrollbar-regular-bg;
    transition: scrollbar-color $transition-duration;

    .ReactVirtualized__Grid {
        outline: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        transition: $transition;
        background: $scrollbar-regular-bg;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        transition: $transition;
        background-color: $scrollbar-regular-thumb-bg;
        border-radius: 5px;
    }

    &:hover {
        scrollbar-color: $scrollbar-active-thumb-bg $scrollbar-active-bg;
        scrollbar-width: 6px;

        ::-webkit-scrollbar-track {
            background: $scrollbar-active-bg;
        }

        ::-webkit-scrollbar-thumb {
            background-color: $scrollbar-active-thumb-bg;
        }
    }
}

.ps .ps__rail-x,
.ps .ps__rail-y {
    background-color: #eee;
    opacity: 0.6 !important;
}
