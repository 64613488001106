@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    background-color: $white;

    .wrapper {
        padding: 1.5rem;
        box-sizing: border-box;
    }

    .notice {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1rem 1.5rem;
        text-transform: uppercase;
    }
}
