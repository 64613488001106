@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    display: flex;
    flex-direction: row;
    align-items: center;

    .checkbox {
        padding-left: 0;

        &:global {
            &.Mui-checked {
                color: $primary-color;
            }
        }
    }

    .label {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        cursor: pointer;
        @include no-select;
    }
}
