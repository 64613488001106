@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    display: flex;
    flex: 1;

    &.row {
        flex-direction: row;
    }

    &.column {
        flex-direction: column;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-start {
        justify-content: flex-start;
    }

    &.justify-space-between {
        justify-content: space-between;
    }

    &.justify-space-around {
        justify-content: space-around;
    }

    &.justify-space-evenly {
        justify-content: space-evenly;
    }

    &.align-center {
        align-items: center;
    }

    &.align-start {
        align-items: flex-start;
    }
}
