@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.rowWrapper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.rowWrapperDragDisabled {
  cursor: default;
}

.rowOuter {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.row {
  min-width: 150px;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  display: flex;
  position: relative;
  border-radius: 0 20px 20px 0;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: var(--tree-view-bg-color, #dedede);
  }
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rowLandingPad {
  border: none;
  box-shadow: none;
  outline: none;

  * {
    opacity: 0 !important;
  }

  &::before {
    border-radius: 0 20px 20px 0;
    background-color: var(--tree-view-bg-color, #cdcdcd);
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rowCancelPad {
  //
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rowSearchMatch {
  //
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rowSearchFocus {
  //
}

.rowContents {
  flex: 1;
  display: flex;
  position: relative;
  height: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
}

.rowLabel {
  flex: 1;
  max-width: 100%;

  a {
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 30px;
    color: inherit;
    outline: 0;
  }

  span {
    display: block;
    min-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.collapseButton,
.expandButton {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  z-index: 2;
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

/**
 * Line for under a node with children
 */
.lineChildren {
  height: 100%;
  display: inline-block;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.lineBlock {
  height: 100%;
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
}

.absoluteLineBlock {
  @extend .lineBlock;
  position: absolute;
  top: 0;
}
