@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

// single date picker

.text-field {
    flex: 1;

    // ELEMENTS

    > div {
        display: flex;
        flex-direction: row-reverse;
        padding-right: 0;
        border: none;
        font: inherit;

        // icon button

        > div {
            margin-left: 0.25rem;
        }

        // border component

        fieldset {
            border: none !important;
        }
    }

    // MODIFIERS

    &.default {

        > div {
            background-color: $input-background-color;
            @include border-radius($input-border-radius);
        }

        .input {
            font-weight: 400;
            padding: ($input-padding-y + $input-border-width) 0 ($input-padding-y + $input-border-width) ($input-padding-x + $input-border-width);
        }
    }

    &.filter {

        > div {
            background-color: $grey-200;
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 1.25rem;
            @include border-radius(0);

            // icon button

            > div {
                width: 2rem;

                svg {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }

        input {
            width: 5.5rem;
            color: $grey-700;
            height: auto;
            line-height: 1.25rem;
            padding: 0.5rem 0 0.5rem 0.25rem;
        }
    }
}

// date range

.range-wrapper {
    background-color: $input-background-color;
    border-radius: $input-border-radius;
    display: flex;
    flex-direction: row;
    align-items: center;

    .divider {
        width: 0.0625rem;
        height: 2rem;
        background-color: $grey-400;
    }
}
