@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    display: flex;
    flex-grow: 1;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    padding: 0 0.5rem;

    .tree {
        width: 100%;
    }

    .item {
        max-width: 100%;
    }

    .content {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-right: 1rem;
        overflow: hidden;
        user-select: none;

        &.selected {
            .label {
                font-weight: bolder;
            }
            background-color: rgba($text-secondary, 0.1);
        }
    }

    .labelWrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
    }

    .label {
        font-family: $font-quicksand;
        max-width: 13rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
