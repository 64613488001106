@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

$badge-size: 1.5rem;

.root {
    position: relative;
    display: inline-block;

    .badge {
        position: absolute;
        right: calc(-#{$badge-size} / 2);
        top: calc(-#{$badge-size} / 2);
        text-align: center;
        font-size: 0.875rem;
        font-weight: 700;
        color: $white;
        padding: 0 0.5rem;
        background-color: $grey-800;
        line-height: $badge-size;
        min-width: $badge-size;
        height: $badge-size;
        transition: transform 0.15s ease;
        @include border-radius(1rem);

        &:global {
            &.base-Badge-invisible {
                transform: scale(0);
            }
        }
    }

    &.is-expanded {
        display: block;
    }
}
