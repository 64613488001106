@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    min-width: 200px;
    max-width: 300px;
    max-height: 300px;

    .uploadInfo {
        position: absolute;
        bottom: 10%;
        padding: rc(16 64);
        text-align: center;
        font-weight: bolder;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.4);
        transition: opacity $transition-duration ease;
    }

    &:hover {
        .overlay {
            opacity: 1;
        }
    }
}
