@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    position: relative;
    background-color: transparent;
    cursor: pointer;
    display: inline-block;
    width: 20rem;

    // ELEMENTS

    .icon {
        position: absolute;
        left: 1rem;
        top: 50%;
        @include transform(translateY(-50%));
    }

    .input {
        input {
            outline: none;
            font-weight: 400;
            font-size: $input-font-size;
            color: $black;
            border: none;
            padding: $input-padding-y ($input-padding-x + 2rem);
            width: 100%;
            line-height: rc(23);
            @include border-radius($input-border-radius);
            @include transition(all 0.3s);

            @include placeholder {
                text-transform: uppercase;
                font-size: 0.875rem;
                color: $grey-500;
            }
        }
    }

    .close-button {
        position: absolute;
        right: 1rem;
        top: 50%;
        @include transform(translateY(-50%));
    }

    // STATES

    &:disabled {
        cursor: initial;
    }

    &:hover,
    &:focus {
        background-color: $hover-background-color;
    }

    &:disabled {
        color: $grey-400;

        &:hover {
            filter: none;
        }
    }

    // MODIFIERS

    // Sizes

    &.size-large {
        .icon {
            left: 1.5rem;
        }

        .input {
            input {
                font-size: 1rem;
                padding: $input-padding-y * 1.5 ($input-padding-x * 2 + 2rem);
            }
        }
    }

    &.expanded {
        display: block;
        box-sizing: border-box;
        width: 100%;

        .input {
            input {
                background-color: $grey-50;
                display: block;
                box-sizing: border-box;
                @include border-radius(0);
            }
        }
    }

    // STATES

    &.is-active {
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: $primary-color;
            width: 0.5rem;
            @include transition(all $transition-duration);
        }
    }
}
