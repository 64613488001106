// BASE

@import 'base/reset';
@import 'base/typography';

// VENDORS

@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~react-image-lightbox/style.css';
@import './vendor/prismjs';
@import './vendor/scrollbar';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.disable-scroll {
    overflow: hidden;
}

body {
    background-color: #f4f6f8;
    height: 100%;
}

a {
    text-decoration: none;
}

#root {
    height: 100%;
}

:not(pre) > code {
    font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier,
        monospace;
    background-color: #eeeeee;
    padding: 2px 4px;
    direction: ltr;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    font-size: 14px;
}

.ReactModalPortal {
    > .ReactModal__Overlay {
        z-index: 2000 !important;
    }
}

.tree-select-component {
    .dropdown-content {
        height: 54vh;
        overflow-y: scroll;
    }
}

.link {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}
