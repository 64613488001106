@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&family=Roboto:wght@400;500;900&display=swap');

// we can't use "*" here because it will break old UI
html,
body {
    font-family: 'Quicksand', sans-serif;
}

.cursor-pointer {
    cursor: pointer;
}

.has-underline {
    text-decoration: underline;
}

a.link {
    text-decoration: underline;
}
