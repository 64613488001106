@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.button {
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: $input-font-size;
    font-weight: 600;
    outline: none;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    @include border-radius($button-border-radius);
    @include transition(all 0.3s);

    // ELEMENTS

    .icon {
        display: inline-block;
        min-width: 1rem;

        &.start {
            margin-right: 0.5rem;
        }

        &.end {
            margin-left: 0.5rem;
        }
    }

    .loader {
        display: inline-block;
        margin-right: 0.5rem;
        margin-left: -0.5rem;
    }

    // STATES

    &:disabled {
        cursor: initial;
    }

    // MODIFIERS

    // Variants

    &.variant-default {
        background-color: $primary-color;
        border: none;
        color: $white;
        padding: $button-padding-y $button-padding-x;

        .icon {
            &.start {
                margin-left: -0.5rem;
            }

            &.end {
                margin-right: -0.5rem;
            }
        }

        &:hover,
        &:focus {
            filter: brightness(90%);
        }

        &:disabled {
            color: $grey-500;
            background-color: $grey-200;

            &:hover {
                filter: none;
            }
        }

        @each $modifier, $color in $file-format-colors {
            &.file-format-#{$modifier} {
                background-color: $color;
            }
        }
    }

    &.variant-text,
    &.variant-text-primary {
        background-color: transparent;
        border: none;
        padding: $button-padding-y ($button-padding-x - $button-spacing);
        text-decoration: none;

        &:hover,
        &:focus {
            background-color: $hover-background-color;
        }

        &:disabled {
            color: $grey-400;

            &:hover {
                filter: none;
                background-color: transparent;
            }
        }
    }

    &.variant-outlined {
        background-color: transparent;
        border: 1px solid $primary-color;
        color: $primary-color;
        padding: $button-padding-y $button-padding-x;
        text-transform: none;

        &:hover,
        &:focus {
            background-color: $primary-color;
            color: $white;
        }

        &:disabled {
            color: $grey-400;

            &:hover {
                filter: none;
            }
        }
    }

    &.variant-text {
        color: $black;
    }

    &.variant-text-primary {
        color: $primary-color;
    }

    &.variant-black {
        color: $white;
        background-color: $black;
        border: 0;

        &:hover {
            background-color: rgba($black, 0.9);
        }
    }

    &.variant-icon {
        border: 0;
        background-color: transparent;
        // PHPStorm highlights error on math.div,
        // but in fact it's imported in global.scss file (via sass-resource-loader)
        // so the compiler does not throw any errors
        padding: math.div($button-padding-y, 2) math.div($button-padding-x, 4);
    }

    // Sizes

    &.size-xxs {
        font-size: 0.65rem;
        padding: math.div($button-padding-y, 4) math.div($button-padding-x, 4);
        border-radius: math.div($button-border-radius, 4);
    }

    &.size-xs {
        font-size: 0.75rem;
        padding: math.div($button-padding-y, 2) math.div($button-padding-x, 2);
        border-radius: math.div($button-border-radius, 2);
    }

    &.size-small {
        font-size: 0.875rem;
        padding: $button-padding-y-small $button-padding-x-small;
    }

    &.size-large {
        font-size: 1.25rem;
        padding: $button-padding-y-large $button-padding-x-large;

        .icon {
            &.start {
                margin-right: 0.75rem;
            }

            &.end {
                margin-left: 0.75rem;
            }
        }
    }

    // Colors

    &.color-primary {
        color: $primary-color;
    }

    &.color-success {
        background-color: $success-color;
        color: $white;
    }

    &.color-error {
        background-color: $error-color;
        color: $white;
    }

    &.color-inherit {
        color: inherit !important;
    }

    &.expanded {
        display: block;
        box-sizing: border-box;
        width: 100%;
    }

    &.is-icon-button {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        padding: 0;
        @include border-radius(1rem);

        // ELEMENTS

        .icon {
            &.start,
            &.end {
                margin: 0;
            }
        }

        // MODIFIERS

        &.size-small {
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1.5rem;
        }

        &.variant-text {
            color: $grey-500;
        }
    }
}

.group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;

    // MODIFIERS

    // alignment

    &.align-left {
        justify-content: flex-start;
    }

    &.align-center {
        justify-content: center;
    }

    &.align-right {
        justify-content: flex-end;
    }

    &.align-justify {
        .button {
            flex: 1;
        }
    }
}
