@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    // MODIFIERS

    // types

    &.h1 {
        font-size: rc(30);
        line-height: rc(38);
    }

    &.h2 {
        font-size: rc(19);
        line-height: rc(25);
    }

    &.h3 {
        font-size: rc(14);
        line-height: rc(24);
        margin-bottom: rc(8);
        text-transform: uppercase;
    }

    &.h4 {
        font-size: 0.625rem;
        line-height: 1rem;
    }

    &.label {
        font-size: 1rem;
        text-transform: none;
    }

    &.label2 {
        font-size: 0.875rem;
    }

    &.label3 {
        font-size: rc(14);
        line-height: rc(18);
        color: #cccccc;
    }

    &.caption {
        font-size: 0.75rem;
        line-height: 1rem;
    }

    &.caption2 {
        font-size: 0.5rem;
        line-height: 1rem;
        color: #525252;
    }

    &.caption3 {
        font-size: rc(10);
        line-height: rc(25);
    }

    &.caption4 {
        font-size: rc(8);
        line-height: rc(28);
        color: #9f9f9f;
    }

    &.p {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    // colors

    &.color-primary {
        color: $primary-color;
    }

    &.color-success {
        color: $primary-color;
    }

    &.color-warning {
        color: $warning-color;
    }

    &.color-error {
        color: $error-color;
    }

    &.color-text-primary {
        color: $text-primary;
    }

    &.color-text-secondary {
        color: $text-secondary;
    }

    &.color-inherit {
        color: inherit;
    }

    // alignment

    &.align-left {
        text-align: left;
    }

    &.align-center {
        text-align: center;
    }

    &.align-right {
        text-align: right;
    }

    &.uppercase {
        text-transform: uppercase;
    }

    &.noMargin {
        margin: 0;
    }
}
